<script setup>
import {
  usePayment,
  useRefNumber,
  useStkPush,
  useVerifyPayment,
  usePaymodes,
  useVerifyStk,
} from "./payment_business_logic";
import ViewBills from "@/components/billing/view_bills/ViewBills.vue";
import SelectedBillsTable from "./SelectedBillsTable.vue";
import PaymentOptions from "./PaymentOptions.vue";
import ViewReceipt from "../receipts/ViewReceipt.vue";
import {
  getFormattedMoney,
  getPaymode,
  arrayNotEmpty,
  stringNotEmpty,
} from "@/utils/functions";
import {
  paymodes as paymodeLabels,
  paymentErrors,
  requestStatus,
  actions,
} from "@/utils/constants";
import { billStatuses } from "@/components/billing/view_bills/view_bill_business_logic";
import {
  snackbarData,
  resetSnackbarOnMount,
} from "@/components/utils/snackbar/logic";
import { computed, ref, nextTick, onMounted } from "vue";
import ActionButton from "../utils/ActionButton.vue";
import { useRouter } from "@/utils/vue_helpers";
import ViewReceipts from "../receipts/ViewReceipts.vue";
import Dialog from "../utils/Dialog.vue";

const props = defineProps({
  billsToPay: {
    type: Array,
    default: null,
  },
  directPayment: {
    type: Boolean,
    default: false,
  },
  billNumbersToPay: {
    type: Array,
    default: null,
  },
});

resetSnackbarOnMount();

const tabs = {
  SELECT_BILLS: 0,
  PAY: 1,
  VIEW_RECEIPT: 2,
};
const currentTab = ref(tabs.SELECT_BILLS);
const goTo = {
  selectBills() {
    currentTab.value = tabs.SELECT_BILLS;
  },
  async pay() {
    currentTab.value = tabs.PAY;
    await nextTick();
    document.getElementById("tab-container").scrollIntoView(true);
  },
  viewReceipt() {
    currentTab.value = tabs.VIEW_RECEIPT;
  },
};

const {
  billsToPay,
  fetchBillsMatchingBillNumbers,
  fetchingBillsMatchingBillNumbers,
  billsToPayNotEmpty,
  addOrRemoveBillFromSelectedBills,
} = usePayment();

const { refNumber, refNumberNotEmpty, setRefNumber, settingRefNumber } =
  useRefNumber();

const goStraightToPay = ref(false);

const prePaymentProcessed = ref(false);

const { route, routeNotEmpty } = useRouter();

async function setBillsToPay() {
  if (routeNotEmpty.value) {
    const setBillsToPayFromProps = arrayNotEmpty(props.billsToPay);
    if (setBillsToPayFromProps) {
      billsToPay.value = props.billsToPay;
    } else {
      const billNumbersAsString = arrayNotEmpty(props.billNumbersToPay)
        ? props.billNumbersToPay
        : route.value?.query?.["bill_numbers"]?.toString();
      const billNumbersAsStringNotEmpty = stringNotEmpty(billNumbersAsString);
      if (billNumbersAsStringNotEmpty) {
        const fetchedBills = await fetchBillsMatchingBillNumbers(
          billNumbersAsString
        );
        billsToPay.value = [...fetchedBills, ...billsToPay.value];
      }
    }
  }
}

async function checkShouldGoStraightToPay() {
  if (arrayNotEmpty(billsToPay.value) && routeNotEmpty.value) {
    goStraightToPay.value =
      !!route.value.query?.direct_payment || props.directPayment;
    if (goStraightToPay.value) {
      await onPayClicked();
    }
  }
}

onMounted(async () => {
  if (routeNotEmpty.value) {
    await setBillsToPay();
    await checkShouldGoStraightToPay();
  }
  prePaymentProcessed.value = true;
});

const preProcessingPayment = computed(() => {
  const loadingBeforeGoingDirectlyToPayment =
    !prePaymentProcessed.value && settingRefNumber.value;
  return (
    fetchingBillsMatchingBillNumbers.value ||
    loadingBeforeGoingDirectlyToPayment
  );
});

const {
  stkData,
  stkDataNotEmpty,
  makingStkPush,
  makeStkPush,
  makeStkPushRequestStatus,
} = useStkPush();

const { verifyPayment, verifyingPayment } = useVerifyPayment();

const { paymodes } = usePaymodes();

const {
  verifyStk,
  verifyStkStatus,
  verifyingStk,
  stkTimeout,
  cancelVerifyStk,
  stkVerificationHasError,
  resetVerifyStkWhenPayClicked,
} = useVerifyStk();

const billIdsToPay = computed(() => {
  const billIds = billsToPay.value.map((bill) => bill.id);
  return billIds;
});

const billsToPayTotal = computed(() => {
  let total = 0;
  for (const bill of billsToPay.value) {
    const billTotal = Number(bill.bills_invoices[0].invoice.invoice_amount);
    total += billTotal;
  }
  return total;
});

function billIsSelectedForPayment(bill) {
  const selectedBill = billsToPay.value.find(
    (billToPay) => billToPay.id === bill.id
  );
  const selectedBillNotEmpty = selectedBill !== undefined;
  return selectedBillNotEmpty;
}

async function onPayClicked() {
  try {
    await setRefNumber(billsToPay.value);
    if (refNumberNotEmpty.value) {
      goTo.pay();
    }
  } catch (error) {
    console.log(error);
  }
}

function getPaymentErrorMessage(error) {
  const errorMsg = error?.response?.data?.error || error;
  const errorMsgNotEmpty = !!errorMsg && error !== "";
  if (errorMsgNotEmpty) {
    switch (errorMsg) {
      case paymentErrors.transactionNotFound:
        return "Payment not found, please ensure you have paid";
      case paymentErrors.amountPaidNotSufficient:
        return "Payment amount not sufficient";
      case paymentErrors.stkPushFailed:
        return "STK push failed";
      default:
        return "An error occurred";
    }
  }
  return null;
}

function errorIsTransactionNotFound(error) {
  const errorMsg = error?.response?.data?.error;
  const errorMsgNotEmpty = !!errorMsg;
  const isTransactionError = errorMsg === paymentErrors.transactionNotFound;
  if (errorMsgNotEmpty && isTransactionError) {
    return true;
  }
  return false;
}

function setReceipt(receiptsArray) {
  const receiptsArrayNotEmpty = arrayNotEmpty(receiptsArray);
  if (receiptsArrayNotEmpty) {
    // receiptUnderView.value = receiptsArray[0];
    receiptsUnderView.value = receiptsArray;
    snackbarData.text = "Payment Successful";
    snackbarData.color = "success";
    snackbarData.open = true;
    goTo.viewReceipt();
  }
}

async function onPayWithStkClicked(phoneNumber) {
  try {
    resetVerifyStkWhenPayClicked();
    await makeStkPush({
      amount: billsToPayTotal.value,
      phoneNumber,
      refNumber: refNumber.value,
      transactionDesc: "Eportal",
    });
    const paymode = getPaymode(paymodeLabels.mobileMoney, paymodes.value);
    const stkVerificationFunc = async () => {
      return await verifyPayment({
        refNumber: refNumber.value,
        paymodeId: paymode.id,
      });
    };
    try {
      const receipts = await verifyStk({
        verificationFunc: stkVerificationFunc,
      });

      setReceipt(receipts);
    } catch (error) {
      const errorMsg = getPaymentErrorMessage(error);
      const errorMsgNotEmpty = !!errorMsg;
      if (errorMsgNotEmpty) {
        snackbarData.text = errorMsg;
        snackbarData.color = "error";
        snackbarData.open = true;
      }
    }
  } catch (error) {
    snackbarData.text = "An error has occurred";
    snackbarData.color = "error";
    snackbarData.open = true;
  }
}

const receiptUnderView = ref(null);
const receiptUnderViewNotEmpty = computed(() => {
  return !!receiptUnderView.value;
});
const receiptsUnderView = ref(null);
const moreThanOneReceipt = computed(() => {
  return !!receiptsUnderView.value && receiptsUnderView.value.length > 1;
});

const verifyingPaybill = ref(false);
async function onVerifyPaybillClicked() {
  verifyingPaybill.value = true;
  const paymode = getPaymode(paymodeLabels.mobileMoney, paymodes.value);
  try {
    const receipts = await verifyPayment({
      refNumber: refNumber.value,
      paymodeId: paymode.id,
    });
    setReceipt(receipts);
  } catch (error) {
    console.log(error);
    snackbarData.text = getPaymentErrorMessage(error);
    snackbarData.color = "error";
    snackbarData.open = true;
  } finally {
    verifyingPaybill.value = false;
  }
}

const overallStkStatus = {
  stkLoading: computed(() => {
    return (
      makeStkPushRequestStatus.value === requestStatus.SENDING ||
      verifyStkStatus.value === requestStatus.SENDING
    );
  }),
  makingStkPush: computed(() => {
    return makeStkPushRequestStatus.value === requestStatus.SENDING;
  }),
  stkPushFailed: computed(() => {
    return makeStkPushRequestStatus.value === requestStatus.ERROR;
  }),
  verifying: computed(() => {
    return verifyStkStatus.value === requestStatus.SENDING;
  }),
  verificationFailed: computed(() => {
    return stkVerificationHasError.value;
  }),
  transactionNotFound: computed(() => {
    return verifyStkStatus.value === paymentErrors.transactionNotFound;
  }),
  cancelled: computed(() => {
    return verifyStkStatus.value === requestStatus.CANCELLED;
  }),
};

overallStkStatus.errorMessage = computed(() => {
  if (stkVerificationHasError.value) {
    return getPaymentErrorMessage(verifyStkStatus.value);
  } else if (overallStkStatus.stkPushFailed.value) {
    return getPaymentErrorMessage(paymentErrors.stkPushFailed);
  }
  return null;
});
overallStkStatus.errorOccurred = computed(() => {
  const hasError =
    overallStkStatus.stkPushFailed.value ||
    overallStkStatus.verificationFailed.value;
  return hasError;
});

const verifyingBankPayment = ref(false);
const paymentErrorDialogOpen = ref(false);
const paymentErrorDialogTitle = ref("");
const paymentErrorDialogText = ref("");
async function onVerifyBankPaymentClicked() {
  verifyingBankPayment.value = true;
  const paymode = getPaymode(paymodeLabels.directBanking, paymodes.value);
  try {
    const receipts = await verifyPayment({
      refNumber: refNumber.value,
      paymodeId: paymode.id,
    });
    setReceipt(receipts);
  } catch (error) {
    console.log(error);
    snackbarData.text = getPaymentErrorMessage(error);
    snackbarData.color = "error";
    snackbarData.open = true;
    paymentErrorDialogTitle.value = "Bank Payment Verification Error";
    paymentErrorDialogText.value =
      "The payment could not be verified. Please visit your nearsest subcounty revenue office for assistance";
    paymentErrorDialogOpen.value = true;
  } finally {
    verifyingBankPayment.value = false;
  }
}
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="auto" class="text-h5 font-weight-bold primary--text"
            >Pay For Bills</v-col
          >
        </v-row>
        <template v-if="!preProcessingPayment">
          <v-row v-if="currentTab !== tabs.VIEW_RECEIPT">
            <v-col cols="12" md="10">
              <v-tabs id="tab-container" class="ml-2" :value="currentTab">
                <v-tabs-slider color="secondary" />
                <v-tab
                  class="text-none"
                  :disabled="currentTab !== tabs.SELECT_BILLS"
                >
                  Select Bills
                </v-tab>
                <v-tab class="text-none" :disabled="currentTab !== tabs.PAY">
                  Pay
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-tabs-items :value="currentTab" touchless>
                <v-tab-item>
                  <v-row>
                    <v-col cols="12">
                      <ViewBills
                        class="view-bills-container"
                        :billStatusToFetch="billStatuses.UNPAID"
                      >
                        <template #selectBill="{ bill }">
                          <ActionButton
                            v-if="billIsSelectedForPayment(bill)"
                            :action="actions.canUnselect"
                            inTable
                            @click="addOrRemoveBillFromSelectedBills(bill)"
                          />

                          <ActionButton
                            v-else
                            :action="actions.canSelect"
                            inTable
                            @click="addOrRemoveBillFromSelectedBills(bill)"
                          />
                        </template>
                      </ViewBills>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-divider class="primary" />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="font-weight-bold">
                      Bills To Pay
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <SelectedBillsTable
                        :bills="billsToPay"
                        :billsTotal="billsToPayTotal"
                        showRemoveBill
                        @removeBillClicked="addOrRemoveBillFromSelectedBills"
                      >
                        <template v-if="billsToPayNotEmpty" #payButton>
                          <v-btn
                            color="success"
                            :loading="settingRefNumber"
                            class="text-none"
                            @click="onPayClicked"
                          >
                            Pay
                          </v-btn>
                        </template>
                      </SelectedBillsTable>
                    </v-col>
                  </v-row>

                  <v-row v-if="!billsToPayNotEmpty" justify="center">
                    <v-col cols="auto" class="font-weight-bold error--text">
                      Please select at least one bill to pay
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item>
                  <v-row>
                    <v-col cols="auto">
                      <v-btn
                        plain
                        color="primary"
                        class="text-none"
                        @click="goTo.selectBills()"
                      >
                        <v-icon left>mdi-arrow-left</v-icon> Add or Remove Bills
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <SelectedBillsTable
                        :bills="billsToPay"
                        :billsTotal="billsToPayTotal"
                        @removeBillClicked="addOrRemoveBillFromSelectedBills"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="billsToPayNotEmpty">
                    <v-col cols="12">
                      <PaymentOptions
                        :refNumber="refNumber"
                        :formattedTotalBillAmount="
                          getFormattedMoney(billsToPayTotal)
                        "
                        :verifyingPaybill="verifyingPaybill"
                        :verifyingBankPayment="verifyingBankPayment"
                        :stkTimeout="stkTimeout"
                        :stkStatus="overallStkStatus"
                        @payWithStkClicked="onPayWithStkClicked"
                        @verifyPaybillClicked="onVerifyPaybillClicked"
                        @verifyBankPaymentClicked="onVerifyBankPaymentClicked"
                        @cancelStk="cancelVerifyStk"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item>
                  <template v-if="!!receiptsUnderView">
                    <ViewReceipt
                      v-if="!moreThanOneReceipt"
                      :receipt="receiptsUnderView[0]"
                      :show-go-back="false"
                    />
                    <ViewReceipts
                      v-else
                      :receipts="receiptsUnderView"
                      :show-go-back="false"
                    >
                    </ViewReceipts>
                  </template>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-row>
            <v-col cols="12">
              <v-progress-linear color="success" indeterminate />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto"> Processing Payment </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <Dialog :dialogOpen.sync="paymentErrorDialogOpen" max-width="600">
      <template #title>
        <span class="error--text">{{ paymentErrorDialogTitle }}</span>
      </template>
      <template #text>
        <span class="error--text">{{ paymentErrorDialogText }}</span>
      </template>
    </Dialog>
  </v-container>
</template>

<style lang="scss" scoped>
:deep(.view-bills-container .v-input--checkbox) {
  & {
    margin: 0;
    padding: 0;
  }

  & .success--text {
    min-height: 0;
  }

  & .v-input__slot {
    margin-bottom: 0;
  }
}
</style>
