<script setup>
import { computed } from "vue";
import { arrayNotEmpty } from "@/utils/functions";
import { getBillAmount, getFormattedMoney } from "@/utils/functions";
import Table from "../utils/Table.vue";
import ActionButton from "../utils/ActionButton.vue";
import { actions } from "@/utils/constants";

const props = defineProps({
  bills: {
    type: Array,
    default: () => [],
  },
  billsTotal: {
    type: [String, Number],
    default: null,
  },
  showRemoveBill: {
    type: Boolean,
    default: false,
  },
});

const billsNotEmpty = computed(() => {
  return arrayNotEmpty(props.bills);
});
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <Table
          :headers="[
            { text: 'Bill Number', value: 'bill_number' },
            {
              text: 'Bill Amount',
              value: 'billAmount',
              fn: (bill) => getFormattedMoney(getBillAmount(bill)),
            },
            ...(showRemoveBill
              ? [
                  {
                    text: 'Remove Bill',
                    value: 'removeBill',
                    textAlign: 'text-right',
                  },
                ]
              : []),
          ]"
          :items="bills"
        >
          <template #item.removeBill="{ item: bill }">
            <ActionButton
              :action="actions.removeItem"
              inTable
              @click="$emit('removeBillClicked', bill)"
            >
            </ActionButton>
          </template>
        </Table>

        <!-- <v-simple-table>
          <template #default>
            <thead>
              <tr class="primary">
                <th class="text-left white--text">Bill Number</th>
                <th class="text-left white--text">Bill Amount</th>
                <th v-if="showRemoveBill" class="text-right white--text">
                  Remove Bill
                </th>
              </tr>
            </thead>

            <tbody>
              <template v-if="billsNotEmpty">
                <tr v-for="bill in props.bills">
                  <td>
                    <div>
                      {{ bill.bill_number }}
                    </div>
                  </td>
                  <td>
                    <div>
                      {{ getFormattedMoney(getBillAmount(bill)) }}
                    </div>
                  </td>
                  <td v-if="showRemoveBill" align="right">
                    <div class="d-flex flex-wrap justify-end">
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="error"
                            @click="$emit('removeBillClicked', bill)"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </template>
                        <span>Remove Bill</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table> -->
      </v-col>
    </v-row>
    <v-row justify="end" align="center">
      <v-col cols="auto">
        <span class="font-weight-bold">Total Amount:</span>
      </v-col>

      <v-col cols="auto">
        <span class="font-weight-bold success--text"
          >Ksh.{{ getFormattedMoney(props.billsTotal) }}</span
        >
      </v-col>

      <v-col cols="auto">
        <slot name="payButton"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>
