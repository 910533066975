import { render, staticRenderFns } from "./Payment.vue?vue&type=template&id=71e6f892&scoped=true&"
import script from "./Payment.vue?vue&type=script&setup=true&lang=js&"
export * from "./Payment.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Payment.vue?vue&type=style&index=0&id=71e6f892&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e6f892",
  null
  
)

export default component.exports