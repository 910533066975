import apiCall from "@/utils/api";
import { requestStatus, paymentErrors } from "@/utils/constants";
import {
  getCounty,
  useRouter,
  userProfile,
  userProfileNotEmpty,
} from "@/utils/vue_helpers";
import {
  stringNotEmpty,
  arrayNotEmpty,
  sleep,
  getUnpaidBills,
} from "@/utils/functions";
import { computed, onMounted, ref } from "vue";

export function usePayment() {
  const { fetchingBillsMatchingBillNumbers, fetchBillsMatchingBillNumbers } =
    prepareFetchBillsMatchingBillNumbers();

  const billsToPay = ref([]);

  const billsToPayNotEmpty = computed(() => {
    return arrayNotEmpty(billsToPay.value);
  });

  const refNumber = ref(null);

  function addOrRemoveBillFromSelectedBills(bill) {
    const billsToPayLength = billsToPay.value.length;
    for (let index = 0; index < billsToPayLength; ++index) {
      const billInBillsToPay = billsToPay.value[index].id === bill.id;
      if (billInBillsToPay) {
        // const billsToPayWithMatchingBillRemoved = billsToPay.value.splice(index, 1)
        // billsToPay.value = billsToPayWithMatchingBillRemoved
        billsToPay.value.splice(index, 1);
        return;
      }
    }
    billsToPay.value = [bill, ...billsToPay.value];
    return;
  }

  // const { route, routeNotEmpty } = useRouter()
  // onMounted(async () => {
  //     if (routeNotEmpty.value) {
  //         const billNumbersAsString = route.value.query['bill_numbers'].toString()
  //         const billNumbersAsStringNotEmpty = stringNotEmpty(billNumbersAsString)
  //         if (billNumbersAsStringNotEmpty) {
  //             const fetchedBills = await fetchBillsMatchingBillNumbers(billNumbersAsString)
  //             billsToPay.value = [...fetchedBills, ...billsToPay.value]
  //         }
  //     }
  // })

  return {
    billsToPay,
    billsToPayNotEmpty,
    fetchBillsMatchingBillNumbers,
    fetchingBillsMatchingBillNumbers,
    addOrRemoveBillFromSelectedBills,
  };
}

function getBillIdsAsCommaSeparatedString(bills) {
  let billIdsString = "";
  bills.forEach((bill, index) => {
    billIdsString += bill.id;
    const notLastItem = index !== bills.length - 1;
    if (notLastItem) {
      billIdsString += ",";
    }
  });
  return billIdsString;
}

function prepareFetchBillsMatchingBillNumbers() {
  const fetchedBills = ref([]);

  const fetchBillsMatchingBillNumbersRequestStatus = ref(
    requestStatus.NOT_SENDING
  );
  const fetchingBillsMatchingBillNumbers = computed(() => {
    return (
      fetchBillsMatchingBillNumbersRequestStatus.value === requestStatus.SENDING
    );
  });

  async function fetchBillsMatchingBillNumbers(billsToFetch) {
    if (userProfileNotEmpty.value) {
      const userIdNumber = userProfile.value.id_number;
      const url = `/api/client/bill?bill_numbers=${billsToFetch}&id_number=${userIdNumber}&bill_status=Unpaid`;
      try {
        fetchBillsMatchingBillNumbersRequestStatus.value =
          requestStatus.SENDING;
        const response = await apiCall({ url, method: "GET" });
        const unpaidBills = getUnpaidBills(response);
        fetchBillsMatchingBillNumbersRequestStatus.value =
          requestStatus.COMPLETE;
        return unpaidBills;
      } catch (error) {
        console.log(error);
        fetchBillsMatchingBillNumbersRequestStatus.value = requestStatus.ERROR;
      }
    }
  }

  return {
    fetchedBills,
    fetchingBillsMatchingBillNumbers,
    fetchBillsMatchingBillNumbers,
  };
}

function getBillNumbers(bills) {
  return bills.map((bill) => bill.bill_number);
}

export function useRefNumber() {
  const refNumber = ref(null);
  const refNumberNotEmpty = computed(() => {
    return stringNotEmpty(refNumber.value);
  });

  const setRefNumberRequestStatus = ref(requestStatus.NOT_SENDING);
  const settingRefNumber = computed(() => {
    return setRefNumberRequestStatus.value === requestStatus.SENDING;
  });
  async function setRefNumber(billsToPay) {
    const billsToPayNotEmpty = arrayNotEmpty(billsToPay);
    if (billsToPayNotEmpty) {
      // const moreThanOneBillToPay = billsToPay.length > 1
      // if (moreThanOneBillToPay) {
      const billNumbers = getBillNumbers(billsToPay);
      const requestData = {
        bill_numbers: billNumbers,
      };
      try {
        setRefNumberRequestStatus.value = requestStatus.SENDING;
        const response = await apiCall({
          url: "/api/client/payment/generate-ref-number",
          data: requestData,
          method: "POST",
        });
        refNumber.value = response.ref_number;
        setRefNumberRequestStatus.value = requestStatus.COMPLETE;
      } catch (error) {
        setRefNumberRequestStatus.value = requestStatus.ERROR;
        console.log(error);
      }
      // }
      // else {
      //     const singleBillToPay = billsToPay[0]
      //     const billNumber = singleBillToPay.bill_number
      //     refNumber.value = billNumber
      // }
    }
  }

  return { refNumber, refNumberNotEmpty, setRefNumber, settingRefNumber };
}

export function useStkPush() {
  const stkData = ref(null);
  const stkDataNotEmpty = computed(() => {
    return stkData !== null && stkData !== undefined;
  });

  const makeStkPushRequestStatus = ref(requestStatus.NOT_SENDING);
  const makingStkPush = computed(() => {
    return makeStkPushRequestStatus.value === requestStatus.SENDING;
  });
  async function makeStkPush({
    amount,
    phoneNumber,
    refNumber,
    transactionDesc = null,
  }) {
    const requestData = {
      amount,
      phone_number: phoneNumber,
      account_ref: refNumber,
      transaction_desc: transactionDesc,
    };
    try {
      makeStkPushRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall({
        url: "/api/client/payment/stk-push",
        data: requestData,
        method: "POST",
      });
      stkData.value = response;
      makeStkPushRequestStatus.value = requestStatus.COMPLETE;
      return response;
    } catch (error) {
      makeStkPushRequestStatus.value = requestStatus.ERROR;
      throw error;
    }
  }

  return {
    stkData,
    stkDataNotEmpty,
    makingStkPush,
    makeStkPush,
    makeStkPushRequestStatus,
  };
}

function getStkPushErrorStatus(error) {
  const errorMsg = error.response.data.error;
  const errorMsgNotEmpty = !!errorMsg;
  if (errorMsgNotEmpty) {
    switch (errorMsg) {
      case paymentErrors.stkPushFailed:
        return paymentErrors.stkPushFailed;
    }
  }

  return requestStatus.ERROR;
}

export function useVerifyPayment() {
  const paymentVerified = ref(false);

  const verifyPaymentRequestStatus = ref(requestStatus.NOT_SENT);
  const verifyingPayment = computed(() => {
    return verifyPaymentRequestStatus.value === requestStatus.SENDING;
  });
  async function verifyPayment({ refNumber, paymodeId }) {
    const url = `/api/client/payment/validate?ref_number=${refNumber}&paymode_id=${paymodeId}&county=${getCounty()}`;
    try {
      verifyPaymentRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall({
        url,
        method: "GET",
      });
      verifyPaymentRequestStatus.value = requestStatus.COMPLETE;
      return response;
    } catch (error) {
      verifyPaymentRequestStatus.value = requestStatus.ERROR;
      // const errorMessage = getPaymentErrorMessage(error)
      throw error;
    }
  }

  return { paymentVerified, verifyPayment, verifyingPayment };
}

export function usePaymodes() {
  const paymodes = ref([]);

  const fetchPaymodesRequestStatus = ref(requestStatus.NOT_SENT);
  const fetchingPaymodes = computed(() => {
    return fetchPaymodesRequestStatus.value === requestStatus.SENDING;
  });
  async function fetchPaymodes() {
    try {
      fetchPaymodesRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall({
        url: "/api/client/payment/payment-modes",
        method: "GET",
      });
      paymodes.value = response.data;
      fetchPaymodesRequestStatus.value = requestStatus.COMPLETE;
    } catch (error) {
      fetchPaymodesRequestStatus.value = requestStatus.ERROR;
      console.log(error);
    }
  }

  onMounted(async () => {
    await fetchPaymodes();
  });

  return { paymodes, fetchingPaymodes, fetchPaymodes };
}

function getStkError(error) {
  const errorMsg = error?.response?.data?.error;
  const errorMsgNotEmpty = !!errorMsg;
  if (errorMsgNotEmpty) {
    return errorMsg;
  }
  return null;
}

export function useVerifyStk() {
  const verifyStkStatus = ref(requestStatus.NOT_SENT);
  const stkVerificationHasError = ref(false);
  const stkTimeout = ref(0);
  const stkTimoutInSeconds = 60;
  let stkTimeoutId = null;
  const verifyingStk = computed(() => {
    return verifyStkStatus.value === requestStatus.SENDING;
  });
  const verifyStkCancelled = computed(() => {
    return verifyStkStatus.value === requestStatus.CANCELLED;
  });

  function updateStkTimeout() {
    if (stkTimeout.value > 0) {
      stkTimeout.value -= 1;
      stkTimeoutId = setTimeout(() => {
        updateStkTimeout();
      }, 1000);
    }
  }

  async function verifyStk({ verificationFunc }) {
    clearTimeout(stkTimeoutId);
    stkVerificationHasError.value = false;
    stkTimeout.value = stkTimoutInSeconds;
    verifyStkStatus.value = requestStatus.SENDING;
    updateStkTimeout();
    let response = null;
    let error = null;
    while (stkTimeout.value > 0 && !verifyStkCancelled.value) {
      try {
        const serverResponse = await verificationFunc();
        response = serverResponse;
        break;
      } catch (responseError) {
        error = responseError;
        if (stkTimeout.value <= 0) {
          break;
        } else {
          await sleep(3000);
        }
      }
    }
    if (!verifyStkCancelled.value) {
      const validResponseReceived = !!response;
      const errorOccurred = !!error;
      if (validResponseReceived) {
        verifyStkStatus.value = requestStatus.COMPLETE;
        return response;
      } else {
        verifyStkStatus.value = requestStatus.ERROR;
        stkVerificationHasError.value = true;
        if (errorOccurred) {
          verifyStkStatus.value = getStkError(error);
          throw error;
        }
        throw requestStatus.ERROR;
      }
    } else {
      return null;
    }
  }

  function cancelVerifyStk() {
    verifyStkStatus.value = requestStatus.CANCELLED;
  }

  function resetVerifyStkWhenPayClicked() {
    verifyStkStatus.value = requestStatus.NOT_SENT;
  }

  // async function verifyStk() {
  //     try {
  //         const response = await verificationFunc()
  //         return response
  //     } catch (error) {
  //         if (stkTimeout.value > 0) {
  //             return await verifyStk()
  //         } else {
  //             verifyingStk.value = false
  //             throw (getPaymentErrorMessage(error))
  //         }
  //     }
  // }

  return {
    verifyStk,
    stkTimeout,
    verifyingStk,
    cancelVerifyStk,
    verifyStkStatus,
    verifyStkCancelled,
    stkVerificationHasError,
    resetVerifyStkWhenPayClicked,
  };
}
