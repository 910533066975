var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.Table,{attrs:{"headers":[
          { text: 'Bill Number', value: 'bill_number' },
          {
            text: 'Bill Amount',
            value: 'billAmount',
            fn: (bill) => _setup.getFormattedMoney(_setup.getBillAmount(bill)),
          },
          ...(_vm.showRemoveBill
            ? [
                {
                  text: 'Remove Bill',
                  value: 'removeBill',
                  textAlign: 'text-right',
                },
              ]
            : []),
        ],"items":_vm.bills},scopedSlots:_vm._u([{key:"item.removeBill",fn:function({ item: bill }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.removeItem,"inTable":""},on:{"click":function($event){return _vm.$emit('removeBillClicked', bill)}}})]}}])})],1)],1),_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Total Amount:")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v("Ksh."+_vm._s(_setup.getFormattedMoney(_setup.props.billsTotal)))])]),_c('v-col',{attrs:{"cols":"auto"}},[_vm._t("payButton")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }