<script setup>
import { ref } from "vue";
import Dialog from "./Dialog.vue";

const dialogOpen = ref(false);
</script>

<template>
  <div>
    <template v-if="$mobileBreakpoint">
      <div @click="dialogOpen = !dialogOpen">
        <slot name="mobileActivator"></slot>
      </div>
      <Dialog :dialogOpen.sync="dialogOpen">
        <template #title>
          <div class="text-body-1">
            <slot name="title"></slot>
          </div>
        </template>
        <template #text>
          <slot name="content"></slot>
        </template>
      </Dialog>
    </template>

    <v-tooltip v-else top>
      <template #activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
          <slot name="activator"></slot>
        </div>
      </template>

      <slot name="content"></slot>
    </v-tooltip>
  </div>
</template>
