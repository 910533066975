<script setup>
import LabelledField from "@/components/utils/LabelledField";
import {
  exactCharacterLength,
  fieldRequired,
  isNumber,
} from "@/utils/input_validation";
import PaybillInstructions from "./PaybillInstructions.vue";
import { examplePhoneNumber } from "@/utils/constants";
import {
  userProfile,
  userDataLoading,
  countyIsKirinyaga,
  countyIsKilifi,
} from "@/utils/vue_helpers";
import { formatPhoneNumberForPayment } from "@/utils/functions";
import { computed, ref, watchEffect } from "vue";
import PaymentOption from "./PaymentOption.vue";
import TooltipOrDialog from "../utils/TooltipOrDialog.vue";

const props = defineProps({
  formattedTotalBillAmount: {
    type: [String, Number],
    default: null,
  },
  refNumber: {
    type: [String],
    default: null,
  },
  stkStatus: {
    type: Object,
    default: null,
  },
  stkTimeout: {
    type: [Number, String],
    default: null,
  },
  verifyingPaybill: {
    type: Boolean,
    default: false,
  },
  verifyingBankPayment: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["payWithStk"]);

const stkForm = ref(null);

const paybillBusinessNumber = process.env.VUE_APP_PAYBILL_BUSINESS_NUMBER;
const equityBankAccountNumber = process.env.VUE_APP_EQUITY_BANK_ACCOUNT_NUMBER;
const coopBankAccountNumber = process.env.VUE_APP_COOP_BANK_ACCOUNT_NUMBER;
const kcbBankAccountNumber = process.env.VUE_APP_KCB_BANK_ACCOUNT_NUMBER;

const bankPaymentOptions = computed(() => {
  const options = {
    equity: !!equityBankAccountNumber
      ? `EQUITY: ${equityBankAccountNumber}`
      : "",
    kcb: !!kcbBankAccountNumber ? `KCB: ${kcbBankAccountNumber}` : "",
    coop: !!coopBankAccountNumber ? `COOP: ${coopBankAccountNumber}` : "",
  };
  const optionsToDisplay = [];
  if (countyIsKirinyaga.value) {
    optionsToDisplay.push(options.equity, options.coop, options.kcb);
  } else if (countyIsKilifi.value) {
    optionsToDisplay.push(options.coop);
  }
  return optionsToDisplay;
});

const phoneNumberModel = ref("");
function submitStkForm() {
  const formValid = stkForm.value.validate();
  if (formValid) {
    emit("payWithStkClicked", phoneNumberModel.value);
  }
}

const verifyingPayment = computed(() => {
  return (
    props.verifyingPaybill === true ||
    props.stkStatus.stkLoading.value ||
    props.verifyingBankPayment === true
  );
});

const stkHasErrorMessage = computed(() => {
  const message = props.stkStatus.errorMessage.value;
  return !!message && message !== "";
});

watchEffect(() => {
  if (!userDataLoading.value) {
    const defaultPhoneNumber = userProfile.value.phone_number1;
    const formattedPhoneNumber =
      formatPhoneNumberForPayment(defaultPhoneNumber);
    phoneNumberModel.value = formattedPhoneNumber;
  }
});
</script>

<template>
  <v-container fluid class="pa-md-0">
    <v-row>
      <v-col
        cols="auto"
        class="font-weight-bold primary--text text-center text-md-left"
      >
        Select your preferred payment option
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <PaymentOption>
          <template #title> STK Push </template>
          <template #content>
            <v-card-text>
              Enter a Safaricom phone number and we will invoke a payment to the
              provided number
            </v-card-text>

            <v-card-text>
              <v-form ref="stkForm" @submit.prevent.prevent="submitStkForm">
                <LabelledField
                  :label="`Phone Number (e.g. 0${examplePhoneNumber})`"
                  required
                >
                  <v-text-field
                    outlined
                    dense
                    class="text-field-background"
                    v-model="phoneNumberModel"
                    :disabled="
                      stkStatus.stkLoading.value ||
                      verifyingPayment ||
                      userDataLoading
                    "
                    :rules="[fieldRequired, isNumber, exactCharacterLength(10)]"
                    :loading="userDataLoading"
                  />
                </LabelledField>
              </v-form>
            </v-card-text>

            <v-card-actions class="justify-center px-4 flex-grow-1 align-end">
              <v-btn
                color="success"
                class="text-none"
                :disabled="stkStatus.stkLoading.value || verifyingPayment"
                @click="submitStkForm"
              >
                <template v-if="!stkStatus.stkLoading.value">
                  Pay Ksh.{{ formattedTotalBillAmount }}
                </template>
                <template v-else>
                  <span>Processing</span>
                  <v-progress-circular
                    class="ml-2"
                    size="24"
                    indeterminate
                    color="success"
                  />
                </template>
              </v-btn>
            </v-card-actions>

            <v-card-text
              v-if="stkStatus.errorOccurred.value || stkStatus.cancelled.value"
              class="d-flex flex-column align-center"
            >
              <div v-if="stkStatus.errorOccurred" class="error--text">
                {{
                  stkHasErrorMessage
                    ? `${props.stkStatus.errorMessage.value}`
                    : ""
                }}
              </div>
              <div v-if="stkStatus.cancelled.value">
                STK payment request cancelled
              </div>

              <TooltipOrDialog>
                <template #title> Payment Instructions </template>

                <template #mobileActivator>
                  <v-card
                    outlined
                    flat
                    class="mt-2"
                    :style="{ borderColor: 'red' }"
                  >
                    <v-card-text class="error--text py-0 px-1 text-center"
                      >Click here to view payment instructions</v-card-text
                    >
                  </v-card>
                </template>

                <template #activator>
                  <div
                    :class="{
                      'error--text text-center': true,
                    }"
                  >
                    <span>Follow these instructions to pay</span>
                    <v-icon class="error--text ml-md-2">mdi-information</v-icon>
                  </div>
                </template>

                <template #content>
                  <PaybillInstructions
                    :paybillBusinessNumber="paybillBusinessNumber"
                    :refNumber="refNumber"
                    :formattedTotalBillAmount="formattedTotalBillAmount"
                    :emphasisTextColorClass="
                      $mobileBreakpoint ? 'black--text' : 'white--text'
                    "
                  />
                </template>
              </TooltipOrDialog>
            </v-card-text>

            <v-card-text
              v-if="stkStatus.verifying.value"
              class="d-flex flex-column align-center"
            >
              <div>Please wait for {{ stkTimeout }} seconds</div>
              <v-btn
                color="error"
                text
                class="text-none"
                @click="$emit('cancelStk')"
                >Cancel</v-btn
              >
            </v-card-text>
          </template>
        </PaymentOption>
      </v-col>

      <v-col cols="12" md="6">
        <PaymentOption>
          <template #title> Pay with Mpesa Paybill </template>

          <template #content>
            <v-card-text>
              <PaybillInstructions
                :paybillBusinessNumber="paybillBusinessNumber"
                :refNumber="refNumber"
                :formattedTotalBillAmount="formattedTotalBillAmount"
                emphasisTextColorClass="black--text"
              />
            </v-card-text>

            <v-card-actions class="justify-center px-4">
              <v-btn
                :color="'success'"
                class="text-none"
                :disabled="verifyingPayment"
                @click="$emit('verifyPaybillClicked')"
              >
                <template v-if="!verifyingPaybill"> Verify Payment </template>
                <div v-else class="d-flex align-center">
                  <span>Verifying Payment</span>
                  <v-progress-circular
                    indeterminate
                    size="24"
                    class="ml-4"
                    color="success"
                  />
                </div>
              </v-btn>
            </v-card-actions>
          </template>
        </PaymentOption>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <PaymentOption>
          <template #title>Bank Payment</template>
          <template #content>
            <v-card-text>
              <ol>
                <li>Visit any Bank Branch or Agent</li>
                <li>
                  Use the following bank deposit revenue accounts
                  <ul>
                    <template v-for="bankPaymentOption in bankPaymentOptions">
                      <li
                        v-if="!!bankPaymentOption"
                        class="font-weight-bold black--text"
                      >
                        {{ bankPaymentOption }}
                      </li>
                    </template>
                  </ul>
                </li>
                <li>
                  Use Reference number:
                  <span class="font-weight-bold black--text">{{
                    refNumber
                  }}</span>
                </li>
                <li>
                  Pay Amount Ksh
                  <span class="font-weight-bold black--text">{{
                    formattedTotalBillAmount
                  }}</span>
                </li>
              </ol>
            </v-card-text>

            <v-card-actions class="justify-center px-4">
              <v-btn
                color="success"
                class="text-none"
                :disabled="verifyingPayment"
                @click="$emit('verifyBankPaymentClicked')"
              >
                <template v-if="!verifyingBankPayment">Verify Payment</template>
                <div v-else class="d-flex align-center">
                  <span>Verifying Payment</span>
                  <v-progress-circular
                    indeterminate
                    size="24"
                    class="ml-4"
                    color="success"
                  />
                </div>
              </v-btn>
            </v-card-actions>
          </template>
        </PaymentOption>

        <!-- <v-card>
          <v-card-title> Bank Payment </v-card-title>

          <v-card-text>
            <ol>
              <li>Visit any Bank Branch or Agent</li>
              <li>
                Use the following bank deposit revenue accounts
                <ul>
                  <li>Equity: {{ equityBankAccountNumber }}</li>
                  <li>COOP: {{ coopBankAccountNumber }}</li>
                  <li>KCB: {{ kcbBankAccountNumber }}</li>
                </ul>
              </li>
              <li>Use Reference number: {{ refNumber }}</li>
              <li>Pay Amount Ksh {{ formattedTotalBillAmount }}</li>
            </ol>
          </v-card-text>

          <v-card-actions class="justify-center px-4">
            <v-btn
              color="success"
              class="text-none"
              :disabled="verifyingPayment"
              >Verify Payment</v-btn
            >
          </v-card-actions>
        </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>
