<script setup>
import ViewReceipt from "./ViewReceipt.vue";
import { useDownloadFile } from "@/utils/vue_helpers";
import ActionButton from "../utils/ActionButton.vue";
import { actions } from "@/utils/constants";

const props = defineProps({
  receipts: {
    type: Array,
    default: null,
  },

  showGoBack: {
    type: Boolean,
    default: true,
  },
});

const { downloadFile } = useDownloadFile();
function onDownloadReceiptClicked(receiptNumber,event) {
  event.stopPropagation()
  downloadFile({ receiptNumber: receiptNumber });
}
</script>
<template>
  <v-container fluid class="px-md-0">
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-h6 primary--text text-center">
        Receipts
      </v-col>
    </v-row>
    <v-row
      ><v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel v-for="(receipt, index) in receipts">
            <v-expansion-panel-header>
              <template #default="{ open }">
                <v-row align="center">
                  <v-col cols="auto">
                    <span class="flex-grow-0">Receipt {{ index + 1 }}</span>
                  </v-col>
                  <v-col cols="auto">
                    <span class="ml-md-2 flex-grow-0"
                      >Amount: Ksh {{ receipt.amount }}</span
                    >
                  </v-col>
                  <!-- <div class="flex-grow-1 d-flex justify-end mr-4"> -->
                  <v-spacer />
                  <v-col v-if="!open" cols="auto" class="mr-md-4">
                    <ActionButton
                      in-table
                      :action="actions.downloadReceipt"
                      @click="
                        onDownloadReceiptClicked(receipt.receipt_number,$event)
                      "
                    >
                    </ActionButton>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ViewReceipt
                :receipt="receipt"
                :show-go-back="false"
              ></ViewReceipt>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> </v-col
    ></v-row>
  </v-container>
</template>
